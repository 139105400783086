import { useNavigate } from '@remix-run/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchInputNew from '#app/components/common/forms/search-input-new'
import { Icon } from '#app/components/ui/icon'
import { Sheet, SheetTrigger, SheetContent } from '#app/components/ui/sheet'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { GenderCategorySelect } from '../shared-components/gender-category-select'
import { PredictiveSearchResults } from '../shared-components/predictive-search-result'
import { SearchSuggestions } from '../shared-components/search-suggestion'
import {
	getSearchType,
	createSearchUrl,
	handleSearchChange,
	handleSuggestionClick,
	handleCategoryChange,
} from '../utils/search-functions'

type CategoryType = 'men' | 'kids' | 'women' | 'all'

interface MobileSearchProps {
	lastVisitedLanding: string
}

export default function MobileSearch({
	lastVisitedLanding,
}: MobileSearchProps) {
	const [open, setOpen] = useState(false)
	const [term, setTerm] = useState('')
	const [showSuggestions, setShowSuggestions] = useState(true)

	const navigate = useNavigate()
	const { t } = useTranslation('navbar')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	const [selectedCategory, setSelectedCategory] =
		useState<CategoryType>('women')

	useEffect(() => {
		if (lastVisitedLanding === '/men-landing') {
			setSelectedCategory('men')
		} else if (lastVisitedLanding === '/kids-landing') {
			setSelectedCategory('kids')
		} else if (lastVisitedLanding === '/women-landing') {
			setSelectedCategory('women')
		}
	}, [lastVisitedLanding])

	const scrollableRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (!open) {
			setTerm('')
			setShowSuggestions(true)
		}
	}, [open])

	useEffect(() => {
		setShowSuggestions(true)
	}, [term])

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const scrollTop = e.currentTarget.scrollTop
		if (scrollTop > 10) {
			setShowSuggestions(false)
		}
	}
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const url = createSearchUrl(term, selectedCategory)
		navigate(url)
		setOpen(false)
	}

	const handleSearchChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) =>
		handleSearchChange(e, setTerm)

	const handleSuggestionClickWrapper = (suggestion: string, category: string) =>
		handleSuggestionClick(suggestion, category, navigate, setOpen)

	const handleCategoryChangeWrapper = (category: CategoryType) =>
		handleCategoryChange(category, setSelectedCategory)

	const shouldShowSuggestions = () => {
		return getSearchType(term) === 'regular'
	}

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger className="md:hidden" asChild>
				<Icon
					onClick={() => trackAmplitudeEvent('search initiated')}
					name="magnifying-glass"
					className="h-6 w-6"
				/>
			</SheetTrigger>

			<SheetContent side="right" hiddenClose className="flex flex-col p-4">
				{/* Category Select */}
				<GenderCategorySelect
					onClose={() => setOpen(false)}
					selectedCategory={selectedCategory}
					onCategoryChange={handleCategoryChangeWrapper}
					searchTerm={term}
				/>

				{/* Search Input */}
				<form onSubmit={handleSubmit}>
					<SearchInputNew
						type="text"
						value={term}
						placeholder={t('search_bar_placeholder')}
						autoFocus
						onChange={handleSearchChangeWrapper}
					/>
				</form>
				<div
					ref={scrollableRef}
					onScroll={handleScroll}
					className="flex-1 overflow-y-auto"
				>
					<AnimatePresence mode="wait">
						{shouldShowSuggestions() && showSuggestions && (
							<motion.div
								key="search-suggestions"
								initial={{ opacity: 0, y: -8 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -8 }}
								transition={{ duration: 0.2 }}
							>
								<SearchSuggestions
									searchTerm={term}
									onSuggestionClick={handleSuggestionClickWrapper}
									selectedCategory={selectedCategory}
								/>
							</motion.div>
						)}
					</AnimatePresence>

					<PredictiveSearchResults
						searchTerm={term}
						category={selectedCategory}
						onClose={() => setOpen(false)}
						onSearch={() => {
							handleSubmit(new Event('submit') as any)
							setOpen(false)
						}}
					/>
				</div>
			</SheetContent>
		</Sheet>
	)
}
