import { Link, useLocation } from '@remix-run/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerDropdownMenu } from '#app/components/navigation/customer-dropdown-menu.tsx'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links.tsx'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { useCartStore } from '#app/store/cart'
import { cn } from '#app/utils/misc'
import {
	type CategoryStoryblok,
	type NavigationCategoriesStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import DesktopSearch from '../../features/search/desktop-components/desktop-search'
import MobileSearch from '../../features/search/mobile-components/mobile-search'
import { Icon } from '../ui/icon'
import MobileNavBar from './mobile-navbar'
import NavItem from './nav-item/index'
import HeaderKeysRow, {
	getKidsCategories,
	getMenCategories,
	getWomenCategories,
} from './quick-links-header'

const genderKeys = ['women', 'men', 'kids']

export default function Navbar({
	navigationData,
	sessionLanguage,
	userId,
}: {
	navigationData: NavigationCategoriesStoryblok[]
	sessionLanguage: string
	userId: string | null
}) {
	const location = useLocation()
	const [lastVisitedLanding, setLastVisitedLanding] = useState('/women-landing')
	const [isSearchOverlayVisible, setSearchOverlayVisible] = useState(false)
	let { t } = useTranslation('navbar')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	const navItems = [
		{ link: '/women-landing', name: t('women_header') },
		{ link: '/men-landing', name: t('men_header') },
		{ link: '/kids-landing', name: t('kids_header') },
	]
	const basePath =
		genderKeys.find(keyword => lastVisitedLanding.includes(keyword)) || ''
	useEffect(() => {
		if (location.pathname.endsWith('-landing')) {
			const newLanding = location.pathname
			setLastVisitedLanding(prevLanding => {
				if (prevLanding !== newLanding) {
					return newLanding
				}
				return prevLanding
			})
		}
	}, [location.pathname])
	let headerQuickLinks
	let genderContentFromStoryblok: NavigationCategoriesStoryblok | undefined
	let brandsContentFromStoryblok:
		| (CategoryStoryblok | ParentCategoryStoryblok)
		| undefined

	switch (basePath) {
		case 'women': {
			genderContentFromStoryblok = navigationData.find(
				item => item.title === t('women_header'),
			)
			brandsContentFromStoryblok = navigationData
				.find(item => item.title === t('brands_header'))
				?.categories?.find(item => item.title === t('women_header'))
			headerQuickLinks = getWomenCategories(
				t,
				genderContentFromStoryblok?.categories,
			)
			break
		}
		case 'men': {
			genderContentFromStoryblok = navigationData.find(
				item => item.title === t('men_header'),
			)
			brandsContentFromStoryblok = navigationData
				.find(item => item.title === t('brands_header'))
				?.categories?.find(item => item.title === t('men_header'))
			headerQuickLinks = getMenCategories(
				t,
				genderContentFromStoryblok?.categories,
			)
			break
		}
		case 'kids': {
			genderContentFromStoryblok = navigationData.find(
				item => item.title === t('kids_header'),
			)
			brandsContentFromStoryblok = navigationData
				.find(item => item.title === t('brands_header'))
				?.categories?.find(item => item.title === t('kids_header'))
			headerQuickLinks = getKidsCategories(
				t,
				genderContentFromStoryblok?.categories,
			)
			break
		}
		default: {
			brandsContentFromStoryblok = navigationData
				.find(item => item.title === t('brands_header'))
				?.categories?.find(item => item.title === t('women_header'))
			genderContentFromStoryblok = navigationData.find(
				item => item.title === t('women_header'),
			)
			headerQuickLinks = getWomenCategories(
				t,
				genderContentFromStoryblok?.categories,
			)
		}
	}

	const { count } = useCartStore()
	let language = sessionLanguage
	const toggleSearchOverlay = useCallback(() => {
		setSearchOverlayVisible(prev => !prev)
	}, [])

	useEffect(() => {
		const handleGlobalKeyDown = (event: KeyboardEvent) => {
			if (
				(event.key === 's' && event.ctrlKey) ||
				(event.key === 'k' && event.metaKey)
			) {
				toggleSearchOverlay()
			}
		}

		document.addEventListener('keydown', handleGlobalKeyDown)
		return () => {
			document.removeEventListener('keydown', handleGlobalKeyDown)
		}
	}, [toggleSearchOverlay])

	const isActive = (name: string) => {
		let pathNameWords = location.pathname.split('/')
		if (language === 'da') {
			switch (name) {
				case 'Kvinder':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'women'),
					)
				case 'Mænd':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'men'),
					)
				case 'Børn':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'kids'),
					)
				case 'Butikker':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'stores'),
					)
			}
		}
		return pathNameWords.some(word =>
			word.split('-').some(w => w === name.toLowerCase()),
		)
	}
	return (
		<header>
			<nav
				className={cn(
					'relative z-50 max-h-[56px] bg-white px-4 py-4 md:max-h-[86px] md:px-14 md:py-2',
					!isSearchOverlayVisible ? 'border-b border-b-beige-50' : '',
				)}
			>
				<div className="flex flex-col gap-4 md:gap-0">
					<div className="flex items-center gap-1 md:gap-10">
						{/* Is the women men and kids and plenty icon  */}
						<div className="hidden  flex-grow gap-2 transition-all md:flex md:gap-10">
							<Link
								to={'/'}
								aria-label={t('return_last_visited')}
								className="flex items-center justify-center"
								onClickCapture={() =>
									trackAmplitudeEvent('navbar plenty logo clicked')
								}
							>
								<Icon
									className="text-body-md h-[22px] w-[72px]"
									name="plenty"
									aria-hidden="true"
								/>
							</Link>
							{navItems.map(({ link, name }) => {
								return (
									<NavItem
										key={link}
										link={link}
										name={name}
										isActive={isActive(name)}
									/>
								)
							})}
						</div>
						<DesktopSearch lastVisitedLanding={lastVisitedLanding} />
						<div className="hidden gap-2 md:flex">
							<CustomerDropdownMenu userId={userId} />

							<Link
								to={'/shopping-bag'}
								className="relative"
								aria-label={t('shopping_bag_with_items')}
							>
								{count() > 0 && (
									<span className="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-mint-green-80p px-2 py-1 text-xs font-bold leading-none text-black-90">
										{count()}
									</span>
								)}
								<Icon
									className="text-body-md"
									size="lg"
									name="cart"
									aria-hidden="true"
								/>
							</Link>
						</div>

						{/* end of women men and kids and plenty icon */}

						<div className="flex w-full items-center justify-between md:hidden">
							<div className="flex gap-2">
								<MobileNavBar navigationData={navigationData} />

								<MobileSearch lastVisitedLanding={lastVisitedLanding} />
							</div>
							<div className="flex">
								<Link
									to={'/'}
									aria-label="Return to the last visited landing page"
									className="flex items-center justify-center"
								>
									<Icon
										className="text-body-md h-[22px] w-[72px]"
										name="plenty"
										aria-hidden="true"
									/>
								</Link>
							</div>
							<div className="flex gap-2">
								<CustomerDropdownMenu userId={userId} />

								<Link
									to={'/shopping-bag'}
									className="relative"
									aria-label={t('shopping_bag_with_items')}
								>
									{count() > 0 && (
										<span className="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-mint-green-80p px-2 py-1 text-xs font-bold leading-none text-black-90">
											{count()}
										</span>
									)}
									<Icon
										className="text-body-md"
										size="lg"
										name="cart"
										aria-hidden="true"
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</nav>
			{/* Desktop search */}
			<div
				className={cn(
					'z-50 hidden items-center justify-between transition-all md:flex md:h-full',
					shouldShowQuickLinks(location.pathname, lastVisitedLanding)
						? 'relative border-b border-b-beige-50 bg-white px-4 py-2 md:h-full md:px-14'
						: 'invisible opacity-0 md:h-0',
				)}
			>
				<HeaderKeysRow
					keys={headerQuickLinks}
					categoriesToShow={genderContentFromStoryblok}
					brandsToShow={brandsContentFromStoryblok}
				/>
				<PlentyButtonsAndLinks as="nav-sm" className="italic">
					{t('header_shop_now')}
				</PlentyButtonsAndLinks>
			</div>

			{/* Done on Desktop search */}
		</header>
	)
}

const shouldShowQuickLinks = (pathname: string, lastVisitedLanding: string) => {
	// should display just on the pathnames that include this genderKeys and product (pdp)
	const isProductPage = pathname.includes('/product/')
	const isGenderPage = genderKeys.some(keyword => pathname.includes(keyword))

	return isProductPage || isGenderPage || lastVisitedLanding !== '/'
}
