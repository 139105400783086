import { useLocation, useNavigate } from '@remix-run/react'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchInputNew from '#app/components/common/forms/search-input-new'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { cn } from '#app/utils/misc'
import { GenderCategorySelect } from '../shared-components/gender-category-select'
import { PredictiveSearchResults } from '../shared-components/predictive-search-result'
import { SearchSuggestions } from '../shared-components/search-suggestion'
import {
	getSearchType,
	createSearchUrl,
	handleSearchChange,
	handleSuggestionClick,
	handleCategoryChange,
} from '../utils/search-functions'

type CategoryType = 'men' | 'kids' | 'women' | 'all'

export default function DesktopSearch({
	lastVisitedLanding,
}: {
	lastVisitedLanding: string
}) {
	const [open, setOpen] = useState(false)
	const [term, setTerm] = useState('')
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation('navbar')
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const [headerOffset, setHeaderOffset] = useState(0)
	const headerRef = useRef<HTMLElement | null>(null)
	const [totalCount, setTotalCount] = useState<number | null>(null)
	const [hasSuggestions, setHasSuggestions] = useState(false)

	useEffect(() => {
		if (lastVisitedLanding.includes('/men')) {
			setSelectedCategory('men')
		} else if (lastVisitedLanding.includes('/kids')) {
			setSelectedCategory('kids')
		} else if (lastVisitedLanding.includes('/women')) {
			setSelectedCategory('women')
		}
	}, [lastVisitedLanding])

	const [selectedCategory, setSelectedCategory] = useState<CategoryType>(() => {
		if (lastVisitedLanding.includes('/men')) return 'men'
		if (lastVisitedLanding.includes('/kids')) return 'kids'
		if (lastVisitedLanding.includes('/women')) return 'women'
		return 'women' // default
	})

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const queryParam = searchParams.get('query')
		if (queryParam) {
			setTerm(queryParam)
		}
	}, [location])

	useEffect(() => {
		const header = document.querySelector('header')
		if (header) {
			headerRef.current = header
			const updateOffset = () => {
				const headerHeight = header.getBoundingClientRect().height
				setHeaderOffset(headerHeight)
			}

			updateOffset()

			const resizeObserver = new ResizeObserver(updateOffset)
			resizeObserver.observe(header)

			return () => {
				resizeObserver.disconnect()
			}
		}
	}, [])

	useEffect(() => {
		setOpen(false)
	}, [location])

	const handleSearchChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) =>
		handleSearchChange(e, setTerm)

	const handleSuggestionClickWrapper = (suggestion: string, category: string) =>
		handleSuggestionClick(suggestion, category, navigate, setOpen)

	const handleCategoryChangeWrapper = (category: CategoryType) =>
		handleCategoryChange(category, setSelectedCategory)

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const url = createSearchUrl(term, selectedCategory)
		navigate(url)
	}

	const shouldShowSuggestions = () => {
		return getSearchType(term.toUpperCase()) === 'regular'
	}

	const handleTotalCountChange = (count: number) => {
		setTotalCount(count)
	}

	return (
		<div className="hidden flex-grow md:block">
			<form onSubmit={handleSubmit} className="relative">
				<SearchInputNew
					type="text"
					value={term}
					className="flex max-w-[500px]"
					placeholder={t('search_bar_placeholder')}
					onChange={handleSearchChangeWrapper}
					onClick={() => {
						setOpen(true)
						trackAmplitudeEvent('search initiated')
					}}
					showClear={open}
					onClear={() => {
						setTerm('')
						setOpen(false)
					}}
				/>
			</form>

			{open && (
				<>
					<div
						style={{
							top: `${headerOffset}px`,
							height: `calc(100vh - ${headerOffset}px)`,
							background: 'rgba(0, 0, 0, 0.5)',
						}}
						className="fixed inset-x-0"
						onClick={() => {
							trackAmplitudeEvent('search abandon', { searchTerm: term })
							setOpen(false)
						}}
					/>
					<div
						style={{ top: `${headerOffset}px` }}
						className={cn(
							'absolute left-0 right-0 z-50 mx-auto rounded-md bg-white shadow-lg md:w-full md:rounded-none md:shadow-none',
						)}
					>
						<div className="mx-auto md:w-full">
							{term && (
								<div className="p-4 md:pt-2">
									<div className="md:flex md:flex-row md:gap-8">
										<div
											className={cn('md:flex md:flex-col', {
												'md:w-1/4':
													totalCount !== 0 && getSearchType(term) === 'regular',
												'md:w-full':
													totalCount === 0 || getSearchType(term) !== 'regular',
												'md:hidden':
													totalCount === 0 ||
													(!hasSuggestions && location.pathname !== '/'),
											})}
										>
											{location.pathname === '/' && (
												<GenderCategorySelect
													onClose={() => setOpen(false)}
													selectedCategory={selectedCategory}
													onCategoryChange={handleCategoryChangeWrapper}
													searchTerm={term}
												/>
											)}
											{shouldShowSuggestions() && (
												<SearchSuggestions
													searchTerm={term}
													onSuggestionClick={handleSuggestionClickWrapper}
													selectedCategory={selectedCategory}
													onHasSuggestions={setHasSuggestions}
												/>
											)}
										</div>

										<div
											className={cn('md:w-3/4', {
												'md:w-full':
													totalCount === 0 ||
													(!hasSuggestions && location.pathname !== '/'),
											})}
										>
											<PredictiveSearchResults
												searchTerm={term}
												category={selectedCategory}
												onClose={() => setOpen(false)}
												onSearch={() => {
													handleSubmit(new Event('submit') as any)
												}}
												onTotalCountChange={handleTotalCountChange}
												onCategoryChange={handleCategoryChangeWrapper}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}
