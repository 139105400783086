import { type CategoryType } from '../shared-components/predictive-search-result'

export const isStyleNumber = (value: string) => {
	return /^\d{8}$/.test(value)
}

export const isCodeName = (value: string) => {
	return /^[A-Z\s]+$/.test(value) && value.includes(' ')
}

export const getSearchType = (value: string) => {
	if (isStyleNumber(value)) return 'style'
	if (isCodeName(value)) return 'code'
	return 'regular'
}

export const createSearchUrl = (term: string, selectedCategory: string) => {
	const searchType = getSearchType(term)
	return searchType === 'regular'
		? `/clerk-search?query=${encodeURIComponent(
				term,
		  )}&category=${encodeURIComponent(selectedCategory)}`
		: `/clerk-search?query=${encodeURIComponent(
				term,
		  )}&type=${searchType}&category=${encodeURIComponent(selectedCategory)}`
}

export const handleSearchChange = (
	e: React.ChangeEvent<HTMLInputElement>,
	setTerm: (value: string) => void,
) => {
	setTerm(e.target.value)
}

export const handleSuggestionClick = (
	suggestion: string,
	category: string,
	navigate: (url: string) => void,
	setOpen: (value: boolean) => void,
) => {
	const url = `/clerk-search?query=${encodeURIComponent(
		suggestion,
	)}&category=${encodeURIComponent(category)}`
	navigate(url)
	setOpen(false)
}

export const handleCategoryChange = (
	category: CategoryType,
	setSelectedCategory: (category: CategoryType) => void,
) => {
	setSelectedCategory(category)
}
