import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import ScrollableContent from '../scrollable-content'
import { SlideContent, SlideTrigger } from '../slides'
import CategoriesContent from './categories-content'

interface SpecialCategoriesProps {
	category: ParentCategoryStoryblok
	parentCategory: string
}

const SpecialCategories = ({
	category,
	parentCategory,
}: SpecialCategoriesProps) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()

	return (
		<div className="h-fit w-full">
			<nav className="grid w-full gap-6">
				<div>
					<PlentyButtonsAndLinks as="nav-md" className="text-black font-semibold">
						{typeof category.title === 'string' && category.title}
					</PlentyButtonsAndLinks>
				</div>
				{category.categories?.map(
					(subCategories: ParentCategoryStoryblok | CategoryStoryblok) => {
						return (
							<div key={subCategories._uid}>
									<SlideTrigger slideKey={subCategories._uid}>
										<div className="flex cursor-pointer items-center justify-between gap-2" 
										onClickCapture={() =>
											trackAmplitudeEvent(
												'mobile navbar special categories clicked',
												{
													subCategory: subCategories.title,
													parentCategory: parentCategory,
													subCategoryLink: subCategories.link?.url,
												},
											)
										}
										>
											<PlentyButtonsAndLinks as="nav-md" className="text-black">
												{subCategories.title}
											</PlentyButtonsAndLinks>
											{subCategories?.categories &&
												subCategories.categories.length > 0 && (
													<Icon name="chevron-right-arrow" size="lg" />
												)}
										</div>
									</SlideTrigger>
									<SlideContent slideKey={subCategories._uid}>
										<ScrollableContent className="h-full space-y-3">
											<CategoriesContent
												category={subCategories}
												parentCategory={parentCategory || ''}
											/>
										</ScrollableContent>
									</SlideContent>
								</div>
						)
					},
				)}
				</nav>

		</div>
	)
}

export default SpecialCategories
