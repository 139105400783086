import { Link } from '@remix-run/react'
import { motion } from 'framer-motion'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { SheetClose } from '#app/components/ui/sheet'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import PlentyIcon from '../footer-components/plenty-icon'

interface TopNavigationProps {
	navigationData: NavigationCategoriesStoryblok[]
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
}

const TopNavigation = ({
	navigationData,
	selectedCategory,
	onCategoryClick,
}: TopNavigationProps) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<div className="flex flex-row justify-between  pb-1 pt-4">
			<Link to="/">
				<PlentyIcon />
			</Link>
			<div className="flex flex-row space-x-4">
				{navigationData.map(item => {
					const isActive = selectedCategory === item.title
					return (
						<div
							className={cn(
								'relative flex w-full cursor-pointer items-center justify-center',
							)}
							onClick={() => onCategoryClick(item.title!)}
							onClickCapture={() =>
								trackAmplitudeEvent('mobile top navigation category clicked', {
									categoryName: item.title,
								})
							}
							key={item._uid}
						>
							<PlentyButtonsAndLinks
								as="nav-md"
								className={cn(' text-black-90', isActive && 'font-bold')}
							>
								{item.title}
							</PlentyButtonsAndLinks>
							{isActive && (
								<motion.div
									className="absolute bottom-0 h-[2px] w-6 rounded-br-full bg-black-90"
									layoutId="underline"
								/>
							)}
						</div>
					)
				})}
			</div>
			<SheetClose className="flex items-center justify-end">
				<Icon name="close" size="lg" />
			</SheetClose>
		</div>
	)
}

export default TopNavigation
