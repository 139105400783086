import { Link } from '@remix-run/react'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links.tsx'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'

const CategoryGrid = ({
	content,
}: {
	content: CategoryStoryblok | ParentCategoryStoryblok | undefined
}) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()

	// Separate large categories from regular ones
	const [largeCategory, regularCategories] = (content?.categories || []).reduce(
		(
			acc: [
				(CategoryStoryblok | ParentCategoryStoryblok)[],
				(CategoryStoryblok | ParentCategoryStoryblok)[],
			],
			category: CategoryStoryblok | ParentCategoryStoryblok,
		) => {
			if (category.isLarge) {
				acc[0].push(category)
			} else {
				acc[1].push(category)
			}
			return acc
		},
		[[], []],
	)

	const columns = 4 // Number of main category columns
	const categoriesPerColumn = Math.ceil(
		regularCategories.length / (columns - largeCategory.length),
	)

	// Group regular categories into columns
	const groupedCategories = regularCategories.reduce(
		(acc: any, category: any, index: number) => {
			const columnIndex = Math.floor(index / categoriesPerColumn)
			if (!acc[columnIndex]) {
				acc[columnIndex] = []
			}
			acc[columnIndex].push(category)
			return acc
		},
		[],
	)

	const renderCategory = (
		category: CategoryStoryblok | ParentCategoryStoryblok,
		isLarge = false,
	) => (
		<div key={category._uid} className={` ${isLarge ? 'h-full' : ''}`}>
			<Link
				to={category.link?.cached_url || '/'}
				className="mb-3 block"
				onClickCapture={() =>
					trackAmplitudeEvent('navbar category clicked', {
						category: category.title,
						link: category.link?.cached_url,
					})
				}
			>
				<PlentyButtonsAndLinks as="nav-sm" className="font-semibold">
					{category.title}
				</PlentyButtonsAndLinks>
			</Link>
			<div className={isLarge ? 'space-y-2' : ''}>
				{category.categories?.map((subItem: any) => (
					<Link
						key={subItem._uid}
						to={subItem.link.cached_url}
						className="block"
						onClickCapture={() =>
							trackAmplitudeEvent('navbar category clicked', {
								category: subItem.title,
								link: subItem.link.cached_url,
							})
						}
					>
						<div className="group/sub my-2 flex flex-row items-center">
							<PlentyButtonsAndLinks
								as="nav-sm"
								className="text-black-70 hover:font-semibold group-hover/sub:text-black-90 group-hover/sub:opacity-100"
							>
								{subItem.title}
							</PlentyButtonsAndLinks>
						</div>
					</Link>
				))}
			</div>
		</div>
	)

	return (
		<div className="grid grid-cols-9 gap-8">

			{/* Main category columns */}
			<div className="col-span-9">
				<div className="grid grid-cols-4 gap-8">
					{largeCategory &&
						largeCategory.map(
							(
								largeCat: CategoryStoryblok | ParentCategoryStoryblok,
								columnIndex: number,
							) => (
								<div key={columnIndex} className="space-y-8">
									{renderCategory(largeCat, true)}
								</div>
							),
						)}
					{groupedCategories.map(
						(
							column: (CategoryStoryblok | ParentCategoryStoryblok)[],
							columnIndex: number,
						) => (
							<div key={columnIndex} className="space-y-8">
								{column.map(
									(category: CategoryStoryblok | ParentCategoryStoryblok) =>
										renderCategory(category),
								)}
							</div>
						),
					)}
				</div>
			</div>
		</div>
	)
}

export default CategoryGrid
